import React, { Component } from "react";
import { withVueRouter } from "vuereact-combined";
import axios from "@/axios";
import "./../../assets/css/healthCare.css";

class HealthCareDetail extends Component {
  constructor() {
    super();
    this.state = {
      labDetail: "",
      testList: [],
      loading: true,
    };
  }

  componentDidMount() {
    axios
      .post("api/v2/services/healtCare/getLabList", {
        LabId: this.props.$vueRoute.params.labId,
      })
      .then((lab) => {
        this.setState({
          labDetail:
            lab.data.response[0].GetLabListResponse[0].GetLabListResult[0]
              .Labs[0].Lab[0],
        });
      });
    axios
      .post("api/v2/services/healtCare/getTestList", {
        LabId: this.props.$vueRoute.params.labId,
      })
      .then((tests) => {
        this.setState({
          testList:
            tests.data.response[0].GetTestListResponse[0].GetTestListResult[0]
              .Tests[0].Test,
        });
      });
  }

  render() {
    return (
      <div className="container">
        <div className="detail-container mt-2">
          {/* Lab Detail division */}
          <div className="row" style={{ margin: "0px" }}>
            <div className="col-6">
              <div className="detail-data">
                <div className="labLogo">
                  <img src={this.state.labDetail.Logo} />
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="detail-data">
                <div className="detail-item">
                  <h4>{this.state.labDetail.Name}</h4>
                </div>

                <div className="detail-item mt-2">
                  <strong className="font-weight-bold">Address : </strong>
                  <p>{this.state.labDetail.Address}</p>
                </div>
                <div className="detail-item mt-2">
                  <strong className="font-weight-bold">Phone : </strong>
                  <p>{this.state.labDetail.Phone}</p>
                </div>
                <div className="detail-item mt-2">
                  <strong className="font-weight-bold">Email : </strong>
                  <p>{this.state.labDetail.Email}</p>
                </div>
                <div className="detail-item mt-2">
                  <p>{this.state.labDetail.Description}</p>
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
        {/* Test List Division */}
        <div className="detail-container mt-2">
          <div className="row mt-5" style={{ margin: "0px" }}>
            {this.state.testList.map((test) => {
              return (
                <div className="col-3 mt-3">
                  <div className="card">
                    <div classname="card-body">
                      <div
                        className="icon"
                        style={{ height: "200px", width: "100%" }}
                      >
                        <img src={test.Logo[0]} alt={test.Name[0]} />
                      </div>
                    </div>
                    <div className="card-footer"></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
export default withVueRouter(HealthCareDetail);
